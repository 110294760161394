// import React, { useState } from 'react';
import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
// import ButtonGroup from '../elements/ButtonGroup';
// import Button from '../elements/Button';
import Image from '../elements/Image';
// import Modal from '../elements/Modal';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Bgsplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertMobile,
  invertDesktop,
  alignTop,
  invertColor,
  imageFill,
  ...props
}) => {

  // const [videoModalActive, setVideomodalactive] = useState(false);

  // const openModal = (e) => {
  //   e.preventDefault();
  //   setVideomodalactive(true);
  // }

  // const closeModal = (e) => {
  //   e.preventDefault();
  //   setVideomodalactive(false);
  // }   

  const outerClasses = classNames(
    'feature-bg section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  // const outerClasses = classNames(
  //   'hero section center-content',
  //   topOuterDivider && 'has-top-divider',
  //   bottomOuterDivider && 'has-bottom-divider',
  //   hasBgColor && 'has-bg-color',
  //   invertColor && 'invert-color',
  //   className
  // );
  
  
  // const innerClasses = classNames(
  //   'hero-inner section-inner',
  //   topDivider && 'has-top-divider',
  //   bottomDivider && 'has-bottom-divider'
  // );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      
      <div className="container">
        <div className={innerClasses}>
        <div className={splitClasses}>
          <div className="split-item">
            {/* <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800"> */}
            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">  
              {/* <a
                data-video="https://player.vimeo.com/video/174002812"
                href="#0"
                aria-controls="video-modal"
                onClick={openModal}
              > */}
              <h2 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Get started with <span className="text-color-primary">Hexacode Indonesia</span>
              </h2>
              <div className="container-xs">
                <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                At Hexacode Indonesia, we support business throughout the Indonesia including offering a fully managed IT support. We take a forward-looking approach, and provide a business aligned technology road-map. This assists our customers in defining future technology budgets and ensures that technology remains relevant with the business’s needs.
                {/* Hexantium brings blockchain technology to businesses so that they can easily implement web 3.0 into the business fields they run without having to bother studying, creating and managing their own resources. */}
                  </p>
                {/* <div className="reveal-from-bottom" data-reveal-delay="600">
                  <ButtonGroup>
                    <Button tag="a" color="primary" wideMobile href="#">
                      Get started
                      </Button>
                    <Button tag="a" color="dark" wideMobile href="#">
                      View on Github
                      </Button>
                  </ButtonGroup>
                </div> */}
              </div>
              {/* </a> */}
            </div>
            <div className={classNames('split-item-image center-content-mobile reveal-from-right',imageFill && 'split-item-image-fill')}data-reveal-container=".split-item">
            {/* <div className="hero-content"> */}
              <Image
                  src={require('./../../assets/images/get-started-4.png')}
                  alt="Hero"
                  width={600}
                  height={192} />
            </div>
          </div>

          {/* <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://player.vimeo.com/video/174002812"
            videoTag="iframe" /> */}
        </div>
        </div>
      </div>
    </section>
  );
}

Bgsplit.propTypes = propTypes;
Bgsplit.defaultProps = defaultProps;

export default Bgsplit;

import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
// import Cta from '../components/sections/Cta';
import Bgsplit from '../components/sections/Bgsplit';
// import About from '../components/sections/About';


const Home = () => {

  return (
    <>
      <Hero className="illustration-section-01" />
      {/* <About invertMobile topDivider imageFill className="illustration-section-02"/> */}
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <FeaturesTiles/>
      <Bgsplit topDivider />
      {/* <GenericSection/> */}
      <Testimonial topDivider />
      {/* <Cta split /> */}
    </>
  );
}

export default Home;